<script lang="js" setup>
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification();
const ui = useUiStore();
// const cart = useCartStore();
const promo = usePromoStore();
const props = defineProps({
  discounts: {
    type: Array,
    default: () => [],
  },
});

const removeDiscount = async (code) => {
  const rem = await promo.deleteVoucher(code);
  if (rem) {
    ui.modalDiscount = false;
    notify({
      text: "Diskon berhasil dihapus",
      type: "success",
    });
    await fetchCartData();
  }
};
</script>
<template>
  <div id="removeableDiscount">
    <div
      v-for="(d, k) in props.discounts"
      :key="k"
      class="min-h-[72px] py-2 last:pb-4 bg-white justify-between items-center inline-flex w-full"
    >
      <div class="justify-start gap-2 flex items-center">
        <div
          class="px-[3px] align-middle bg-white justify-center items-center flex"
        >
          <svg
            data-v-66d75089=""
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            class="w-[28px] h-[28px] inline"
          >
            <path
              d="M17.5 5.8335V8.16683M17.5 12.8335V15.1668M17.5 19.8335V22.1668M5.83333 5.8335C4.54467 5.8335 3.5 6.87817 3.5 8.16683V11.6668C4.78866 11.6668 5.83333 12.7115 5.83333 14.0002C5.83333 15.2888 4.78866 16.3335 3.5 16.3335V19.8335C3.5 21.1222 4.54467 22.1668 5.83333 22.1668H22.1667C23.4553 22.1668 24.5 21.1222 24.5 19.8335V16.3335C23.2113 16.3335 22.1667 15.2888 22.1667 14.0002C22.1667 12.7115 23.2113 11.6668 24.5 11.6668V8.16683C24.5 6.87817 23.4553 5.8335 22.1667 5.8335H5.83333Z"
              stroke="#737373"
              stroke-width="2.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
        <div class="flex-col justify-start items-start gap-0.5 inline-flex">
          <div
            class="self-stretch h-5 justify-start items-center gap-1 inline-flex"
          >
            <div class="text-gray-900 text-xs font-medium leading-[18px]">
              {{ d.code }}
            </div>
            <div class="w-4 h-4 relative"></div>
          </div>
          <div
            class="self-stretch text-gray-500 text-xs font-normal leading-[18px]"
          >
            {{ formatRupiah(d.discount_amount) }}
          </div>
        </div>
      </div>
      <button v-if="d.method === 'code'" @click="() => removeDiscount(d.code)">
        <LazyIconsClose />
      </button>
      <button
        v-if="d.method === 'automatic'"
        disabled
        class="disabled:opacity-50 hover:cursor-not-allowed"
      >
        <LazyIconsChecked />
      </button>
    </div>
  </div>
</template>
